import React from 'react';
import PropTypes from 'prop-types';
import { StoreContext } from 'redux-react-hook';

import createStore from 'state/createStore';

// via https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

const WrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  const store = createStore();

  return <StoreContext.Provider value={store}>{element}</StoreContext.Provider>;
};

WrapWithProvider.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default WrapWithProvider;
